import * as Sentry from '@sentry/browser';

if (!import.meta.env.DEV && import.meta.env.VITE_SENTRY_JS_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_JS_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    initialScope(scope) {
      scope.setUser({ id: window.user_data.id ?? undefined });
    },
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error === undefined) {
        return null;
      }
    }
  });
  Sentry.getCurrentScope().setUser(window.user_data ?? null);
}

import './assets';
import './utils/logger';
import './alpine.js';
import './plugins/datatables';
import './plugins/jquery-bbq';
import './svelte';
import '@/custom/filters';
